import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import './App.css';

import Login from "./components/ui/login/Login";
import Index from "./components/ui/home/Index";
import Home from "./components/ui/home/Home";
import Appointments from "./components/ui/appointments/Appointments";
import NewAppointment from "./components/ui/appointments/NewAppointment";
import AppointmentDetails from "./components/ui/appointments/AppointmentDetails";
import Roadmap from "./components/ui/roadmap/Roadmaps";
import Administration from "./components/ui/administration/Administration";
import MyTickets from "./components/ui/administration/MyTickets";
import Customers from "./components/ui/customers/Customers";
import CustomerDetails from "./components/ui/customers/CustomerDetails";
import Settings from "./components/ui/settings/Settings";

import { getUserRole } from "./controllers/user.controller";

export function App() {

    return (
        <div>
            <Router>
                <Row className="m-0">

                    <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/citas" element={<Appointments />} />
                    <Route path="/citas/nueva" element={<NewAppointment />} />
                    <Route path="/citas/detalles" element={<AppointmentDetails />} />
                    <Route path="/hojaderuta" element={<Roadmap />} />
                    <Route path="/administracion" element={<Administration />} />
                    <Route path="/miscomprobantes" element={<MyTickets />} />
                    <Route path="/clientes" element={<Customers />} />
                    <Route path="/clientes/detalles" element={<CustomerDetails />} />
                    <Route path="/sistema" element={<Settings />} />
                </Routes>


                </Row>
            </Router>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" />
        </div>
    );
}