import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Col, Card, Badge, Button, Table, Dropdown, Form, Modal, ToggleButton, Alert } from "react-bootstrap";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import listPlugin from '@fullcalendar/list';
import Moment from 'react-moment';
import moment from 'moment'
import 'moment/locale/es-mx';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { params } from '../../../tools/constants'

import { get, post, del, put } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { faBell, faCheck, faCoins, faDownload, faEllipsisV, faEye, faGavel, faPlusCircle, faTasks, faTrash } from '@fortawesome/free-solid-svg-icons';

import NavBar from "../navbar/Navbar";
import { processDate, processDateCalendar, processDateEvents, timeDue, timeSince, timeDueWithBadge } from '../../../tools/tools';



export default function Home() {
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    const [tasks, setTasks] = React.useState([]);
    const [error, setError] = React.useState(<div></div>);
    const [indicators, setIndicators] = React.useState([]);
    const [proceedings, setProceedings] = React.useState([]);
    const [lawers, setLawers] = React.useState([]);
    const [lawer, setLawer] = React.useState({});
    const [showNewTaskModal, setShowNewTaskModal] = React.useState(false);
    const [reminderOptions, setReminderOptions] = React.useState([]);
    const [newTaskProceedingId, setNewTaskProceedingId] = React.useState(null);
    const [newTaskDate, setNewTaskDate] = React.useState(null);
    const [newTaskReminder, setNewTaskReminder] = React.useState(null);
    const [newTaskNotificationChecked, setNewTaskNotificationChecked] = React.useState(false);
    const [newTaskUser, setNewTaskUser] = React.useState({});
    const [selectedLawer, setSelectedLawer] = React.useState({});
    const handleCloseNewTaskModal = () => { setShowNewTaskModal(false); };
    const handleShowNewTaskModal = () => {
        setError()
        getReminderOptions();
        getLawers();
        getAllProceedings();
        setNewTaskNotificationChecked(false);
        setShowNewTaskModal(true);
    };
    const [showNewNotificationModal, setShowNewNotificationModal] = React.useState(false);
    const handleCloseNewNotificationModal = () => { setShowNewNotificationModal(false); };
    const handleShowNewNotificationModal = () => {
        setError()
        getLawers();
        getAllProceedings();
        setShowNewNotificationModal(true);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto']
            }
        });

        document.title = "EAC - Inicio";
        registerLocale('es', es);
        //document.getElementsByClassName('fc-today-button')[0].innerHTML = 'Hoy';
        setupDemoTable()
    }, []);

    const setupDemoTable = () => {
        let content = [];

        content.push(
            {
                type: "Fumigación",
                icon: "/fumigacion.png",
                date: moment(1676898000000),
                day: (moment(1676898000000).format('dddd DD MMM')).toString().toUpperCase().replace('.', ''),
                hour: `${moment(1676898000000).format('hh:mm')} a ${moment(1676898000000).add(2, 'hour').format('hh:mm')}`,
                duration: 2,
                customerName: "Tostado Café Club",
                customerAddress: "Avenida San Martín 123",
                customerCity: "Ciudad de Buenos Aires",
                status: <Badge bg='warning' className='me-2 content-table status' >Pendiente</Badge>
            },
            {
                type: "Fumigación",
                icon: "/fumigacion.png",
                date: moment(1676912400000),
                day: (moment(1676912400000).format('dddd DD MMM')).toString().toUpperCase().replace('.', ''),
                hour: `${moment(1676912400000).format('HH:mm')} a ${moment(1676912400000).add(3, 'hour').format('HH:mm')}`,
                duration: 2,
                customerName: "Colegio Fasta Catherina",
                customerAddress: "Soler 5942",
                customerCity: "Ciudad de Buenos Aires",
                status: <Badge bg='warning' className='me-2 content-table status' >Pendiente</Badge>
            })

        setTableRows(content)
        setTableColumns(['Carátula', 'Cliente', '', 'Estado', 'Encargado', 'Inicio', 'Inactividad', 'Acciones']);
    }

    const init = function () {
        //getEvents();
        //getAllProceedings()
        getCourtFiles();
        getTasks()
        getIndicators();
    }

    const handleNewTaskUser = (event) => {
        setNewTaskUser(event.target.value)
    };

    const handleNewTaskNotification = (event) => {
        setNewTaskNotificationChecked(event)
    };

    const handleNewTaskProceeding = (event) => {
        setNewTaskProceedingId(event.target.value)
    };


    const handleNewTaskReminder = (event) => {
        setNewTaskReminder(event.target.value)
    };

    const handleNewTaskDate = (event) => {
        setNewTaskDate(event);
    };


    const setupOpTable = function () {
        const cols = ['Código', 'Cliente', 'Estado', ''];
        setTableColumns(cols);

    };

    const getTasks = async function () {
        const req = await get('/tasks/home', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processTasks(res)
            //processEvents(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processTasks = function (data) {
        let result = [];
        let aux;

        for (let t of data) {
            if (t.Proceeding) {
                switch (t.Proceeding.Status.name) {
                    case "Deuda":
                        aux = `GDD - ${t.Proceeding.Debt.name}`;
                        break;
                    case "Judicial":
                        aux = `Expediente - ${t.Proceeding.CourtFile.name}`;
                        break;
                }
            } else {
                aux = "";
            }

            if (t.Proceeding.Status.name == "Judicial") {
                t.proceedingUrl = `/expedientes/detalles/?id=${t.Proceeding.id}`
            } else if (t.Proceeding.Status.name == "Deuda") {
                t.proceedingUrl = `/moras/detalles/?id=${t.Proceeding.id}`
            }

            t.proceedingName = aux;
            result.push(t);
        }

        setTasks(result);
    }

    const getIndicators = async function () {
        const req = await get(`/indicators/home`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setIndicators(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }


    const getLawers = async function () {
        const req = await get(`/users/lawers`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setLawers(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getAllProceedings = async function () {
        const req = await get(`/proceedings/all/home`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setProceedings(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getReminderOptions = async function () {
        const req = await get(`/tasks/reminderOptions/`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setReminderOptions(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processOperations = function (operations) {
        let result = [];
        for (let p of operations) {
            p.status = <Badge bg='' className='content-table status' style={{ backgroundColor: `#${p.Status.color}` }}>{p.Status.name}</Badge>
            p.inactividad = timeSince(p.updatedAt)
            p.action = <Link to={`/operaciones/detalles/?id=${p.id}`}><Button className='buttonBlue' variant="primary">Ver detalles</Button></Link>
            result.push(p);
        }
        setTableRows(result);
    }

    const getCourtFiles = async function () {
        const req = await get('/proceedings/all/home', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            let result = [];

            for (let r of res) {
                //r.school = <Badge bg='' className='content-table status' style={{backgroundColor: `${r.debt.school.color}`}}>{r.debt.school.code}</Badge>;
                //r.status.name = <Badge bg='' className='content-table status' style={{backgroundColor: `${r.status.color}`}}>{r.status.name}</Badge>;
                if (r.Status.name == "Judicial") {
                    r.name = r.CourtFile.name;
                    r.type = <FontAwesomeIcon icon={faGavel}></FontAwesomeIcon>
                } else if (r.Status.name == "Deuda") {
                    r.name = r.Debt.name;
                    r.type = <FontAwesomeIcon icon={faCoins}></FontAwesomeIcon>
                }

                r.action = <Link to={`/expedientes/detalles/?id=${r.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
                r.inactividad = timeSince(r.lastUpdate)
                result.push(r);
            }
            setTableRows(result);
            setupTable();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const setupTable = function () {
        const cols = ['', 'Carátula', 'Inactividad', ''];
        setTableColumns(cols);
    };

    const setTaskAsCompleted = async function (taskId, proceedingId) {
        const req = await put(`/tasks/byProceedingId/asCompleted/`, getUserToken(), { taskId: taskId, proceedingId: proceedingId });
        const res = await req.json();

        if (req.status === 200) {
            getTasks();
            getIndicators()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createTask = async function () {
        if (checkNewTask()) {
            const task = {
                proceedingId: newTaskProceedingId != '0' ? newTaskProceedingId : null,
                userId: newTaskUser,
                dueDate: newTaskDate,
                alertId: newTaskReminder,
                title: document.getElementById("newTaskTitle").value,
                description: document.getElementById("newTaskDescription").value,
                isEvent: true,
                sendNotification: newTaskNotificationChecked
            };

            const req = await post(`/tasks/`, getUserToken(), task);
            const res = await req.json();

            if (req.status === 201) {
                getTasks();
                handleCloseNewTaskModal();
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const createNotification = async function () {
        if (checkNewNotification()) {
            const task = {
                proceedingId: newTaskProceedingId != '0' ? newTaskProceedingId : null,
                userId: newTaskUser,
                title: document.getElementById("newNotificationTitle").value,
                body: document.getElementById("newNotificationDescription").value
            };

            const req = await post(`/notifications/`, getUserToken(), task);
            const res = await req.json();

            if (req.status === 201) {
                setNewTaskProceedingId(null)
                setNewTaskUser({})
                handleCloseNewNotificationModal();
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const checkNewTask = () => {
        let result = true;

        if (newTaskUser == '0') {
            result = false;
            showError('No se ha asignado un encargado')
        } else if (document.getElementById("newTaskTitle").value.length <= 1) {
            result = false;
            showError('No se ha escrito el título')
        }

        return result;
    }

    const checkNewNotification = () => {
        let result = true;

        if (newTaskUser == '0') {
            result = false;
            showError('No se ha asignado un encargado')
        } else if (document.getElementById("newNotificationTitle").value.length <= 1) {
            result = false;
            showError('No se ha escrito el título')
        }

        return result;
    }



    const showError = (title) => {
        setError(
            <Alert className='mt-3 mb-0' key='danger' variant='danger'>
                {title}
            </Alert>
        )
    }

    const goToProceeding = (url) => {
        window.location.href = url;
    }

    return (
        <div className='p-0 mt-5' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <Container className='mt-5'>
                <Row className='mt-4 mb-4'>
                    <Col xs={3}>
                        <Card className={`statusCard green administration`}>
                            <Card.Body>
                                <Card.Title>Citas de hoy</Card.Title>
                                <Card.Text>
                                    <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>2</p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col xs={3}>
                        <Card className={`statusCard green administration`}>
                            <Card.Body>
                                <Card.Title>Distancia</Card.Title>
                                <Card.Text>
                                    <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>10.5 km</p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col xs={3}>
                        <Card className={`statusCard green administration`}>
                            <Card.Body>
                                <Card.Title>Inicio jornada</Card.Title>
                                <Card.Text>
                                    <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>10:00</p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col xs={3}>
                        <Card className={`statusCard red administration`}>
                            <Card.Body>
                                <Card.Title>Fin jornada</Card.Title>
                                <Card.Text>
                                    <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>17:00</p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className='mb-5'>
                    <Col>
                        <Card className='cards mt-3'>
                            <Card.Body>
                                <Card.Title>
                                    <Row>
                                        <Col className='mt-2'><span className='mt-5'>Mi hoja de ruta</span></Col>

                                    </Row>
                                </Card.Title>

                                <div className='mt-3 p-1'>
                                    {tableRows.map((r, i) => (
                                        <Row className='p-2 mb-3' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col xs={6} className='align-center-vertically'>
                                                <div>
                                                    <p className='m-0' style={{ fontSize: 12 }}>{r.type}</p>
                                                    <p className='m-0'>{r.customerName}</p>
                                                    <p className='m-0' style={{ fontSize: 12 }}>{r.customerAddress}</p>
                                                    <p className='m-0' style={{ fontSize: 12 }}>{r.customerCity}</p>
                                                </div>

                                            </Col>
                                            <Col xs={4} className='align-center-vertically middle'>
                                                <div>
                                                    <p className='m-0'>{r.day}</p>
                                                    <p className='m-0'>{r.hour}</p>
                                                </div>
                                            </Col>
                                            <Col xs={1} className='align-center-vertically' style={{ textAlign: 'right' }}>
                                                <div>
                                                    <Link to={"/citas/detalles"}>
                                                        <p className='m-0'><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></p>
                                                    </Link>
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}
                                </div>









                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='mb-5'>
                        <Card className='cards mt-3' style={{ height: '100%', marginBottom: -60 }}>
                            <Card.Body>
                                <Card.Title>Clientes con deudas</Card.Title>
                                <Card.Text className='p-1'>
                                    <Row className='p-2 mb-3' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                        <Col xs={6} className='align-center-vertically middle'>
                                            <div>
                                                <p className='m-0'>Tostado Cafe Club</p>
                                            </div>
                                        </Col>

                                        <Col xs={4} className='align-center-vertically middle'>
                                            <div>
                                                <p className='m-0'>$10.000,00</p>
                                            </div>
                                        </Col>
                                        <Col xs={2} className='align-center-vertically' style={{ textAlign: 'right' }}>
                                            <div>
                                            <Link to={"/clientes/detalles"}>
                                                        <p className='m-0'><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></p>
                                                    </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Text>
                            </Card.Body>
                        </Card>


                    </Col>
                </Row>

            </Container>


            <Modal show={showNewTaskModal} onHide={handleCloseNewTaskModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva tarea</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group className=''>
                        <Form.Text>Expediente</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewTaskProceeding(event)}>
                            <option value="0">Seleccionar...</option>
                            {proceedings.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.Status.name == 'Judicial' ? `J - ${p.CourtFile.name}` : `GDD - ${p.Debt.name}`}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Encargado</Form.Text>
                        <Row>
                            <Col>
                                <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewTaskUser(event)}>
                                    <option value="0">Seleccionar...</option>
                                    {lawers.map((p, i) => (
                                        <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col xs={3} style={{ textAlign: 'right' }}>
                                <ToggleButton
                                    className=""
                                    id="newTaskNotification"
                                    type="checkbox"
                                    variant="outline-success"
                                    checked={newTaskNotificationChecked}
                                    value="1"
                                    onChange={(e) => handleNewTaskNotification(e.currentTarget.checked)}
                                >
                                    Notificar
                                </ToggleButton>
                            </Col>
                        </Row>

                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Título</Form.Text>
                        <Form.Control type="text" id="newTaskTitle" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control as="textarea" rows={4} id="newTaskDescription" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' minDate={new Date()} dateFormat="dd/MM/yyyy" selected={newTaskDate} onChange={(d) => handleNewTaskDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Recordar</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewTaskReminder(event)}>
                            <option value="0">No recordar</option>
                            {reminderOptions.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    {error}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewTaskModal}>
                        Cerrar
                    </Button>

                    <Button variant="success" onClick={createTask}>Crear tarea</Button>


                </Modal.Footer>
            </Modal>




            <Modal show={showNewNotificationModal} onHide={handleCloseNewNotificationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva notificación</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group className=''>
                        <Form.Text>Expediente</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewTaskProceeding(event)}>
                            <option value="0">Seleccionar...</option>
                            {proceedings.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.Status.name == 'Judicial' ? `J - ${p.CourtFile.name}` : `GDD - ${p.Debt.name}`}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Encargado</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewTaskUser(event)}>
                            <option value="0">Seleccionar...</option>
                            {lawers.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Título</Form.Text>
                        <Form.Control type="text" id="newNotificationTitle" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control as="textarea" rows={4} id="newNotificationDescription" placeholder="" />
                    </Form.Group>
                    {error}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewNotificationModal}>
                        Cerrar
                    </Button>

                    <Button variant="success" onClick={createNotification}>Enviar notificación</Button>


                </Modal.Footer>
            </Modal>


        </div>
    )
}