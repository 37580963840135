import moment from 'moment';
import {Badge} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCalendarAlt, } from '@fortawesome/free-regular-svg-icons'
import { faFile, faFilePdf, faFileExcel, faFileImage, faFileWord, faFileZipper} from '@fortawesome/free-solid-svg-icons';

export const correctNumberFloat = function (number) {
    if(isNaN(number)){
        return 0;
    }else if(number.length == 0){
        return 0;
    }else{
        return parseFloat(number);
    }
}

export const fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const timeSince = function(date) {
   // const dateGMT_3 = moment(date).add(3, 'hours');
    const dateGMT_3 = moment(date);
    const minutes = parseInt((moment.duration(moment().diff(dateGMT_3))).asMinutes());
    const hours = parseInt((moment.duration(moment().diff(dateGMT_3))).asHours());
    const days = parseInt((moment.duration(moment().diff(dateGMT_3))).asDays());

    if(minutes > 60){
        if(hours > 23){
            //Must return days
            return `${days} ${days != 1 ? 'días': 'día'}`;
        }else{
            //Must return hours
            return `${hours} ${hours != 1 ? 'horas': 'hora'}`;
        }
    }else if(minutes < 1){
        return 'hace instantes'
    }else{
        //Must return minutes
        return `${minutes} ${minutes != 1 ? 'minutos': 'minuto'}`;
    }
};


export const timeDue = function(date) {
    const dateGMT_3 = moment(date).add(3, 'hours');
    const minutes = parseInt((moment.duration(moment().diff(dateGMT_3))).asMinutes());
    const hours = parseInt((moment.duration(moment().diff(dateGMT_3))).asHours());
    const days = parseInt((moment.duration(moment().diff(dateGMT_3))).asDays());

    let result = "";

    if(minutes < -60){
        if(hours < -24){
            //Must return days
            console.log(days)
            result = `${days} ${days != -1 ? 'días': 'día'}`;
        }else{
            //Must return hours
            result = `${hours} ${hours != -1 ? 'horas': 'hora'}`;
        }
    }else if(minutes > -1){
        result = 'instantes'
    }else{
        //Must return minutes
        result = `${minutes} ${minutes != -1 ? 'minutos': 'minuto'}`;
    }

    return result.replace('-', '');
};

export const processDate = function(date) {
    return moment(date).format("DD/MM/YYYY");
};

export const processDateCalendar = function(date) {
    return moment(date).format("YYYY-MM-DD");
};

export const processDateEvents = function(date) {
    const monthNum = moment(date).format("MM");
    let monthLet = "";

    switch(monthNum){
        case "01":
            monthLet = "ENE";
            break;
        case "02":
            monthLet = "FEB";
            break
        case "03":
            monthLet = "MAR";
            break
        case "04":
            monthLet = "ABR";
            break
        case "05":
            monthLet = "MAY";
            break
        case "06":
            monthLet = "JUN";
            break
        case "07":
            monthLet = "JUL";
            break
        case "08":
            monthLet = "AGO";
            break;
        case "09":
            monthLet = "SEP";
            break;
        case "10":
            monthLet = "OCT";
            break;
        case "11":
            monthLet = "NOV";
            break;
        case "12":
            monthLet = "DIC";
            break;
    }
    return `${moment(date).format("DD")} ${monthLet}`;
};

export const timeDueWithBadge = function(date) {
    let b;
    if(date != null){
        const dateGMT_3 = moment(date);
        let minutes = parseInt((moment.duration(moment().diff(dateGMT_3))).asMinutes());
        let hours = parseInt((moment.duration(moment().diff(dateGMT_3))).asHours());
        let days = parseInt((moment.duration(moment().diff(dateGMT_3))).asDays());
        const formated = moment(date).format("DD/MM/YY")
    
        let result = "";
        
    
        if(minutes < -60){
            if(hours < -24){
                //Must return days
                result = `${days} ${days != -1 ? 'días': 'día'} y ${hours % -24} ${hours != -1 ? 'horas': 'hora'}`;

                    b = <Badge bg='success' className=''>Vence el {formated} (en {result.replaceAll('-', '')})</Badge>
                
                
            }else{
                //Must return hours
                result = `${hours} ${hours != -1 ? 'horas': 'hora'}`;
                if(isToday(dateGMT_3)){
                    b = <Badge bg='warning' className=''>Vence hoy (en {result.replace('-', '')})</Badge>
                }else{
                    b = <Badge bg='success' className=''>Vence el {formated} (en {result.replace('-', '')})</Badge>
                }
                
            }
        }else if(minutes > -1){
            //Due
            if(hours >= 24){
                //Must return days
                result = `${days} ${days != 1 ? 'días': 'día'}`;
                if(days >= -1){
                    
                    b = <Badge bg='danger' className=''>Vencido el {formated} (hace {result.replace('-', '')})</Badge>
                }else{
                    b = <Badge bg='danger' className=''>Vencido el {formated} (hace {result.replace('-', '')})</Badge>
                }
                
            }else{
                //Must return hours
                result = `${hours} ${hours != 1 ? 'horas': 'hora'}`;
                if(isToday(dateGMT_3)){
                    b = <Badge bg='danger' className=''>Vencido hoy (hace {result.replace('-', '')})</Badge>
                }else{
                    b = <Badge bg='danger' className=''>Vencido el {formated} (hace {result.replace('-', '')})</Badge>
                }
                
            }           
        }else{
            //Must return minutes
            result = `${minutes} ${minutes != -1 ? 'minutos': 'minuto'}`;
            b = <Badge bg='warning' className=''>Vence el {formated} (en {result.replace('-', '')})</Badge>
        }
    }else{
        b = <Badge bg='primary' className=''>Sin vencimiento</Badge>
    }
    return (b)
    
};

export const isToday = (date) => {
    const date1 = moment(date).format("DD/MM/YYYY");
    const date2 = moment(new Date()).format("DD/MM/YYYY")

    return (date1 == date2)
}

export const convert2Base64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    })
}

export const getFileIcon = (fileName) => {
    if(fileName.includes('.pdf')){
        //PDF
        return faFilePdf
    }else if(fileName.includes('.docx') || fileName.includes('.doc')){
        //WORD
        return faFileWord
    }else if(fileName.includes('.xlsx') || fileName.includes('.xls')){
        //EXCEL
        return faFileExcel
    }else if(fileName.includes('.png') || fileName.includes('.jpg')){
        //IMAGE
        return faFileImage
    }else if(fileName.includes('.zip')){
        //IMAGE
        return faFileZipper
    }else{
        return faFile
    }
}

export const getFileExtension = (fileName) => {
    return "." + fileName.split('.')[fileName.split('.').length - 1];
}

export const delay = ms => new Promise(res => setTimeout(res, ms));
