import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, DropdownButton, Form, Col, Modal, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faGavel, faEye, faSearch, faFilter, faDownload, faEllipsisV, faCoins, faCalendar, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { get, post } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { timeSince } from '../../../tools/tools';
import { triggerBase64Download } from 'common-base64-downloader-react';

export default function CourtFiles() {

    const PAGE_TITLE = "Citas";
    const PAGE_ICON = faCalendarAlt;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);

    const [orderTypes, setOrderTypes] = React.useState(["Ordenar por cliente (A -> Z)", "Ordenar por cliente (Z -> A)", "Ordenar por encargado", "Ordenar por fecha"]);
    const [selectedOrderType, setSelectedOrderType] = React.useState("Ordenar por fecha");
    const [selectedOnlyMine, setSelectedOnlyMine] = React.useState(false);

    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const handleCloseFilterModal = () => { setShowFilterModal(false); };
    const handleShowFilterModal = () => {
        setShowFilterModal(true);
    };

    const handleOrderType = (event) => {
        setSelectedOrderType(event.target.value);
    };

    const handleOnlyMine = (event) => {
        setSelectedOnlyMine(event.target.checked)
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `Plagamix - ${PAGE_TITLE}`;

        setupDemoTable();
    }, []);

    const setupDemoTable = () => {
        let content = [];

        content.push({
            type: "Fumigación",
            icon: "/fumigacion.png",
            date: moment(1674219600000),
            day: (moment(1674219600000).format('dddd DD MMM')).toString().toUpperCase().replace('.', ''),
            hour: `${moment(1674219600000).format('hh:mm')} a ${moment(1674219600000).add(2, 'hour').format('hh:mm')}`,
            duration: 2,
            customerName: "Tostado Café Club",
            customerAddress: "Avenida San Martín 123",
            customerCity: "Ciudad de Buenos Aires",
            status: <Badge bg='success' className='me-2 content-table status' >Realizada</Badge>
        },
            {
                type: "Fumigación",
                icon: "/fumigacion.png",
                date: moment(1676898000000),
                day: (moment(1676898000000).format('dddd DD MMM')).toString().toUpperCase().replace('.', ''),
                hour: `${moment(1676898000000).format('hh:mm')} a ${moment(1676898000000).add(2, 'hour').format('hh:mm')}`,
                duration: 2,
                customerName: "Tostado Café Club",
                customerAddress: "Avenida San Martín 123",
                customerCity: "Ciudad de Buenos Aires",
                status: <Badge bg='warning' className='me-2 content-table status' >Pendiente</Badge>
            },
            {
                type: "Fumigación",
                icon: "/fumigacion.png",
                date: moment(1676912400000),
                day: (moment(1676912400000).format('dddd DD MMM')).toString().toUpperCase().replace('.', ''),
                hour: `${moment(1676912400000).format('HH:mm')} a ${moment(1676912400000).add(3, 'hour').format('HH:mm')}`,
                duration: 2,
                customerName: "Colegio Fasta Catherina",
                customerAddress: "Soler 5942",
                customerCity: "Ciudad de Buenos Aires",
                status: <Badge bg='warning' className='me-2 content-table status' >Pendiente</Badge>
            })

        setTableRows(content)
        setTableColumns(['Carátula', 'Cliente', '', 'Estado', 'Encargado', 'Inicio', 'Inactividad', 'Acciones']);
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <span style={{ color: '#A0A0A0' }}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon><Form.Control className='ms-2' type="text" id="textFee" style={{ width: 250, display: 'inline' }} placeholder="" />
                                    <Button className="ms-2" variant="primary" onClick={handleShowFilterModal}><FontAwesomeIcon icon={faFilter} style={{ marginRight: 8 }} />Filtros</Button>
                                </td>
                                <td className='content-options rows right'>

                                    <Link to='/citas/nueva/'>
                                        <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva cita</Button>
                                    </Link>

                                </td>
                            </tr>
                        </table>

                        {tableRows.length > 0 ?
                            <Container className='pt-3'>
                                {tableRows.map((r, i) => (
                                    <Row className='p-2 mb-3' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                        <Col xs={1}>
                                            <img src={r.icon} width='70'></img>
                                        </Col>
                                        <Col xs={5} className='align-center-vertically'>
                                            <div>
                                                <p className='m-0' style={{ fontSize: 12 }}>{r.type}</p>
                                                <p className='m-0'>{r.customerName}</p>
                                                <p className='m-0' style={{ fontSize: 12 }}>{r.customerAddress} - {r.customerCity}</p>
                                            </div>

                                        </Col>
                                        <Col xs={2} className='align-center-vertically middle'>
                                            <div>
                                                <p className='m-0'>{r.day}</p>
                                                <p className='m-0'>{r.hour}</p>
                                            </div>
                                        </Col>
                                        <Col xs={2} className='align-center-vertically middle'>
                                            <div className='middle'>
                                                <p className='m-0'>{r.status}</p>
                                            </div>
                                        </Col>
                                        <Col xs={1} className='align-center-vertically middle'>
                                            <div>
                                                <p className='m-0'><img className='avatar-40 me-2' src='/avatar_secdevs.png'></img></p>
                                            </div>
                                        </Col>
                                        <Col xs={1} className='align-center-vertically' style={{ textAlign: 'right' }}>
                                            <div>
                                                <Link to={"/citas/detalles"}>
                                                    <p className='m-0'><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></p>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>
                                <Row>
                                    <Col style={{ textAlign: 'center', fontSize: 60 }}>
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ textAlign: 'center' }}>
                                        No hay citas
                                    </Col>
                                </Row>
                            </Container>

                        }

                    </Card>
                </Row>
            </Container>

            <Modal show={showFilterModal} onHide={handleCloseFilterModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Filtros</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Orden</Form.Text>
                        <Form.Select className='cover content rows element' id="" value={selectedOrderType} onChange={(event) => handleOrderType(event)}>
                            {orderTypes.map((p, i) => (
                                <option key={i} id={i} value={p}>{p}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-3'>
                        <Form.Text>Mostrar</Form.Text>
                        <Form.Check
                            className='mt-1'
                            type="checkbox"
                            id=""
                            label="Solo mis citas"
                            checked={selectedOnlyMine}
                            onChange={(event) => handleOnlyMine(event)}
                        />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseFilterModal}>
                        Cerrar
                    </Button>
                    <Button variant="primary">
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}