export const removeTildes = function (chain) {
    return chain.replace(/á/g, "a").replace(/é/g, "e").replace(/í/g, "i").replace(/ó/g, "o").replace(/ú/g, "u");
}

export const pn = function (number) {
    try{
        
        let result = "";

        if(number >= 0){
            const enteros = number.toString().split('.');
            const numeros = enteros[0].split('');
            let i = 0;
            let c = 0;
            let aux = [];
            for(i = numeros.length; i != 0; i--){
                c++;
                if(c == 4){
                    aux.push('.')
                    c = 0;
                }
                aux.push(numeros[i - 1])
            }
            let aux2 = aux.reverse()
            for(let n of aux2){
                result += n
            }
            if(enteros.length > 1){
                result += `,${enteros[1]}`
            }else{
                result += ',00';
            }
        }else{
            const aux3 = number.toString().replace("-", "")
            const enteros = aux3.split('.');
            const numeros = enteros[0].split('');
            let i = 0;
            let c = 0;
            let aux = [];
            for(i = numeros.length; i != 0; i--){
                c++;
                if(c == 4){
                    aux.push('.')
                    c = 0;
                }
                aux.push(numeros[i - 1])
            }
            let aux2 = aux.reverse()
            for(let n of aux2){
                result += n
            }
            if(enteros.length > 1){
                result += `,${enteros[1]}`
            }else{
                result += ',00';
            }

            result = `-${result}`
        }
    
        
        
        return result
    }catch(e){
        return "error"
    }
}