import React, { useEffect } from 'react'
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Col, Badge, Dropdown, Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { faCheck, faFilePdf, faEllipsisV, faCommentAlt, faArrowLeft, faPlusCircle, faGavel, faTrash, faSearch, faTasks, faCogs, faEye, faLink, faDownload, faMap } from '@fortawesome/free-solid-svg-icons'
import '@fortawesome/fontawesome-free/css/all.css';
import NavBar from "../navbar/Navbar";
import WebFont from 'webfontloader';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import moment from 'moment'
import 'moment/locale/es-mx';
import { get, put, post } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { processDate, processDateCalendar, timeDue } from '../../../tools/tools';
import { params } from '../../../tools/constants';


export default function Settings() {

    moment.locale('es-mx');
    const PAGE_TITLE = "Configuración";
    const PAGE_ICON = faCogs;
    const [usersCol, setUsersCol] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [actionCategories, setActionCategories] = React.useState([]);
    const [administratorCategories, setAdministratorCategories] = React.useState([]);

    const [color, setColor] = React.useState("");
    const [showNewActionCategoryModal, setShowNewActionCategoryModal] = React.useState(false);
    const handleCloseNewActionCategoryModal = () => { setShowNewActionCategoryModal(false); };
    const handleShowNewActionCategoryModal = () => {
        setShowNewActionCategoryModal(true);
    };

    const [showNewAdministrationCategoryModal, setShowNewAdministrationCategoryModal] = React.useState(false);
    const handleCloseNewAdministrationCategoryModal = () => { setShowNewAdministrationCategoryModal(false); };
    const handleShowNewAdministrationCategoryModal = () => {
        setShowNewAdministrationCategoryModal(true);
    };
    const [integrations, setIntegrations] = React.useState([]);

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `EAC - ${PAGE_TITLE}`;

        setUsersCol(['', 'Nombre y apellido', 'Correo electrónico', 'Rol', 'Estado', ''])
    }, []);

    const handleColor = (event) => {
        setColor(event.target.value);
    };


    const getUsers = async function () {
        const req = await get('/users', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setUsersCol(['', 'Nombre y apellido', 'Correo electrónico', 'Rol', 'Estado', 'Detalles'])
            processUsers(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processUsers = (data) => {
        let result = [];

        for (let u of data) {
            u.status = u.enabled ? <Badge bg='success' className='content-table status'>Habilitado</Badge> : <Badge bg='danger' className='content-table status'>Bloqueado</Badge>
            if (u.token == getUserToken()) {
                u.actions = <Link to={`/configuracion/usuarios/detalles/`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
            } else {
                u.actions = <Link to={`/sistema/usuarios/detalles/?id=${u.id}`}><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></Link>;
            }

            result.push(u);
        }

        setUsers(result)
    }

    const getActionCategories = async function () {
        const req = await get(`/proceedings/actions/categories`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setActionCategories(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createActionCategory = async function () {
        const data = {
            name: document.getElementById('textActionCategoryName').value,
            color: color
        }
        const req = await post(`/proceedings/actions/categories`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            getActionCategories();
            handleCloseNewActionCategoryModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getAdministrationCategories = async function () {
        const req = await get(`/administration/categories`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setAdministratorCategories(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createAdministrationCategory = async function () {
        const data = {
            name: document.getElementById('textAdministrationCategoryName').value,
            color: color
        }
        const req = await post(`/administration/categories`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            getAdministrationCategories();
            handleCloseNewAdministrationCategoryModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <span style={{ color: '#A0A0A0' }}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <span style={{ fontSize: 18 }}>Usuarios</span>
                                </td>
                                <td className='content-options rows right'>
                                  <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo usuario</Button>
                                </td>
                            </tr>
                        </table>

                        <table striped hover className='mb-3 w-100'>
                            <thead>
                                <tr>
                                    {usersCol.map((c, i) => (
                                        <th key={i} className='content-table head'>{c}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr >
                                    <td className='content-table rows middle align-middle' style={{ width: 70 }}><img className='avatar-40 me-2' src='/avatar_secdevs.png'></img></td>
                                    <td className='content-table rows middle align-middle'>Lucas Mancuso</td>
                                    <td className='content-table rows middle align-middle'>lmancuso@plagamix.com</td>
                                    <td className='content-table rows middle align-middle'>Administrador</td>
                                    <td className='content-table rows middle align-middle'><Badge bg='success' className='content-table status'>Habilitado</Badge></td>
                                    <td className='content-table rows middle align-middle'><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></td>
                                </tr>

                            </tbody>
                        </table>
                    </Card>
                </Row>

                <Row>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <span style={{ fontSize: 18 }}>Zonas de trabajo para turnos</span>
                                </td>
                                <td className='content-options rows right'>
                                    <Button variant="success" ><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva zona</Button>
                                </td>
                            </tr>
                        </table>

                        <p className='mt-3 ps-4 pe-4'>
                            <Row className='p-2 mb-3' style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                <Col xs={7} className='align-center-vertically middle'>
                                    <div>
                                        <p className='m-0'>Ciudad de Buenos Aires</p>
                                    </div>
                                </Col>
                                <Col xs={4} className='align-center-vertically middle'>
                                    <div>
                                        <p className='m-0'>LUN - MIE - VIE</p>
                                    </div>
                                </Col>

                                <Col xs={1} className='align-center-vertically' style={{ textAlign: 'right' }}>
                                    <div>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item ><FontAwesomeIcon icon={faMap} style={{ marginRight: 10, marginLeft: 2 }} />Ver mapa</Dropdown.Item>
                                                <Dropdown.Item ><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>

                            <Row className='p-2 mb-3' style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                <Col xs={7} className='align-center-vertically middle'>
                                    <div>
                                        <p className='m-0'>San Martín</p>
                                    </div>
                                </Col>
                                <Col xs={4} className='align-center-vertically middle'>
                                    <div>
                                        <p className='m-0'>SAB</p>
                                    </div>
                                </Col>

                                <Col xs={1} className='align-center-vertically' style={{ textAlign: 'right' }}>
                                    <div>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item ><FontAwesomeIcon icon={faMap} style={{ marginRight: 10, marginLeft: 2 }} />Ver mapa</Dropdown.Item>
                                                <Dropdown.Item ><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>

                            <Row className='p-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                <Col xs={7} className='align-center-vertically middle'>
                                    <div>
                                        <p className='m-0'>Tres de Febrero</p>
                                    </div>
                                </Col>
                                <Col xs={4} className='align-center-vertically middle'>
                                    <div>
                                        <p className='m-0'>MAR - JUE</p>
                                    </div>
                                </Col>

                                <Col xs={1} className='align-center-vertically' style={{ textAlign: 'right' }}>
                                    <div>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item ><FontAwesomeIcon icon={faMap} style={{ marginRight: 10, marginLeft: 2 }} />Ver mapa</Dropdown.Item>
                                                <Dropdown.Item ><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                        </p>

                    </Card>
                </Row>


            </Container>

            <Modal show={showNewActionCategoryModal} onHide={handleCloseNewActionCategoryModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva categoría de acción</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className='mt-2'>
                        <Form.Text>Nombre</Form.Text>
                        <Row>
                            <Col>
                                <Form.Control type="text" id="textActionCategoryName" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control
                                    type="color"
                                    id="exampleColorInput"
                                    defaultValue="#563d7c"
                                    title="Choose your color"
                                    onChange={(event) => handleColor(event)}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewActionCategoryModal}>
                        Cerrar
                    </Button>

                    <Button variant="success" onClick={createActionCategory}>Crear categoría</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showNewAdministrationCategoryModal} onHide={handleCloseNewAdministrationCategoryModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva categoría de pagos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className='mt-2'>
                        <Form.Text>Nombre</Form.Text>
                        <Row>
                            <Col>
                                <Form.Control type="text" id="textAdministrationCategoryName" placeholder="" />
                            </Col>
                            <Col>
                                <Form.Control
                                    type="color"
                                    id="exampleColorInput"
                                    defaultValue="#563d7c"
                                    title="Choose your color"
                                    onChange={(event) => handleColor(event)}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewAdministrationCategoryModal}>
                        Cerrar
                    </Button>

                    <Button variant="success" onClick={createAdministrationCategory}>Crear categoría</Button>


                </Modal.Footer>
            </Modal>
        </div>
    )
}