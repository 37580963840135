import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, DropdownButton, Form, Col, Modal, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faGavel, faEye, faSearch, faFilter, faDownload, faEllipsisV, faCoins, faCalendar, faCalendarAlt, faFilePdf, faTrash, faCheck, faCross, faTimes } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { get, post } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { timeSince } from '../../../tools/tools';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { pn } from '../../../tools/chains';

export default function CourtFiles() {

    const PAGE_TITLE = "Administración";
    const PAGE_ICON = faCoins;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    const [tableRows2, setTableRows2] = React.useState([]);

    const [orderTypes, setOrderTypes] = React.useState(["Ordenar por cliente (A -> Z)", "Ordenar por cliente (Z -> A)", "Ordenar por encargado", "Ordenar por fecha"]);
    const [selectedOrderType, setSelectedOrderType] = React.useState("Ordenar por fecha");
    const [selectedOnlyMine, setSelectedOnlyMine] = React.useState(false);

    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const handleCloseFilterModal = () => { setShowFilterModal(false); };
    const handleShowFilterModal = () => {
        setShowFilterModal(true);
    };

    const handleOrderType = (event) => {
        setSelectedOrderType(event.target.value);
    };

    const handleOnlyMine = (event) => {
        setSelectedOnlyMine(event.target.checked)
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `Plagamix - ${PAGE_TITLE}`;

        setupDemoTable();
    }, []);

    const setupDemoTable = () => {

        let content2 = [];

        content2.push({
            description: "Servicio de fumigación",
            customer_provider: "Tostado Cafe Club",
            debit: 15000,
            credit: 0,
            date: moment(1676898000000),
            icon: faFilePdf,
            executed: false,
            action: (<Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item ><FontAwesomeIcon icon={faCheck} style={{ marginRight: 10, marginLeft: 2 }} />Marcar como ejecutado</Dropdown.Item>
                    <Dropdown.Item ><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item>
                    <Dropdown.Item ><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item>
                    <Dropdown.Item ><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>)
        },
            {
                description: "Carga de nafta",
                customer_provider: "YPF",
                debit: 0,
                credit: 8000,
                date: moment(1676898000000),
                executed: false,
                action: (<Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item ><FontAwesomeIcon icon={faCheck} style={{ marginRight: 10, marginLeft: 2 }} />Marcar como ejecutado</Dropdown.Item>
                        <Dropdown.Item ><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>)
            }
            ,
            {
                description: "Veneno",
                customer_provider: "YPF",
                debit: 0,
                credit: 3000,
                date: moment(1676898000000),
                icon: faFilePdf,
                executed: true,
                action: (<Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item ><FontAwesomeIcon icon={faTimes} style={{ marginRight: 10, marginLeft: 2 }} />Marcar como no ejecutado</Dropdown.Item>
                        <Dropdown.Item ><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item>
                        <Dropdown.Item ><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item>
                        <Dropdown.Item ><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>)
            })

        let content = [];

        content.push({
            type: "Fumigación",
            icon: "/fumigacion.png",
            date: moment(1674219600000),
            day: (moment(1674219600000).format('dddd DD MMM')).toString().toUpperCase().replace('.', ''),
            hour: `${moment(1674219600000).format('hh:mm')} a ${moment(1674219600000).add(2, 'hour').format('hh:mm')}`,
            duration: 2,
            customerName: "Tostado Café Club",
            customerAddress: "Avenida San Martín 123",
            customerCity: "Ciudad de Buenos Aires",
            status: <Badge bg='success' className='me-2 content-table status' >Realizada</Badge>
        })

        setTableRows2(content)
        setTableRows(content2)
        setTableColumns(['Fecha', 'Descripción', 'Ingreso', 'Egreso', 'Estado', '', '']);
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <span style={{ color: '#A0A0A0' }}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    Cuenta corriente
                                </td>
                                <td className='content-options rows right'>


                                    <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva operación</Button>


                                </td>
                            </tr>
                        </table>

                        <Container>
                            <Row className='mt-4 mb-4'>
                                <Col xs={3}>
                                    <Card className={`statusCard green administration`}>
                                        <Card.Body>
                                            <Card.Title>Ingresos del mes</Card.Title>
                                            <Card.Text>
                                                <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>$15.000,00</p>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col xs={3}>
                                    <Card className={`statusCard red administration`}>
                                        <Card.Body>
                                            <Card.Title>Egresos del mes</Card.Title>
                                            <Card.Text>
                                                <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>$11.000,00</p>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col xs={3}>
                                    <Card className={`statusCard green administration`}>
                                        <Card.Body>
                                            <Card.Title>Balance del mes</Card.Title>
                                            <Card.Text>
                                                <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>$4.000,00</p>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col xs={3}>
                                    <Card className={`statusCard yellow administration`}>
                                        <Card.Body>
                                            <Card.Title>Sin ejecutar</Card.Title>
                                            <Card.Text>
                                                <p className='m-0' style={{ fontSize: 28, fontStyle: 'bold' }}>2</p>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>

                        <table striped hover className='mb-3'>
                            <thead>
                                <tr>
                                    {tableColumns.map((c, i) => (
                                        <th key={i} className='content-table head'>{c}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tableRows.map((r, i) => (
                                    <tr key={i} >
                                        <td className='content-table rows middle align-middle' style={{ width: 150 }}>{<Moment format="DD/MM/YYYY">{r.date}</Moment>}</td>
                                        <td className='content-table rows align-middle'>{r.description}</td>
                                        <td className='content-table rows middle align-middle' style={{ width: 150 }}>{r.debit != null ? `$${pn(r.debit)}` : "---"}</td>
                                        <td className='content-table rows middle align-middle' style={{ width: 150 }}>{r.credit != null ? `$${pn(r.credit)}` : "---"}</td>
                                        <td className='content-table rows middle align-middle' style={{ width: 150 }}>{r.executed ? <Badge className='content-table status' bg='success'>Ejecutado</Badge> : <Badge className='content-table status' bg='danger'>Sin ejecutar</Badge>}</td>
                                        <td className='content-table rows middle align-middle' style={{ width: 40 }}>{r.icon != null ? <FontAwesomeIcon style={{ fontSize: 30 }} icon={r.icon}></FontAwesomeIcon> : ""}</td>
                                        <td className='content-table rows middle align-middle' style={{ width: 100 }}>{r.action}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>



                    </Card>
                </Row>


                <Row>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    Citas realizadas sin comprobantes fiscales
                                </td>
                                <td className='content-options rows right'>



                                </td>
                            </tr>
                        </table>

                        <Container className='pt-3'>
                            {tableRows2.map((r, i) => (
                                <Row className='p-2 mb-3' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col xs={1}>
                                        <img src={r.icon} width='70'></img>
                                    </Col>
                                    <Col xs={5} className='align-center-vertically'>
                                        <div>
                                            <p className='m-0' style={{ fontSize: 12 }}>{r.type}</p>
                                            <p className='m-0'>{r.customerName}</p>
                                            <p className='m-0' style={{ fontSize: 12 }}>{r.customerAddress} - {r.customerCity}</p>
                                        </div>

                                    </Col>
                                    <Col xs={4} className='align-center-vertically middle'>
                                        <div>
                                            <p className='m-0'>{r.day}</p>
                                            <p className='m-0'>{r.hour}</p>
                                        </div>
                                    </Col>

                                    <Col xs={1} className='align-center-vertically middle'>
                                        <div>
                                            <p className='m-0'><img className='avatar-40 me-2' src='/avatar_secdevs.png'></img></p>
                                        </div>
                                    </Col>
                                    <Col xs={1} className='align-center-vertically' style={{ textAlign: 'right' }}>
                                        <div>
                                            <Link to={"/citas/detalles"}>
                                                <p className='m-0'><Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></p>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                        </Container>



                    </Card>
                </Row>

                <Row>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    Facturas emitidas
                                </td>
                                <td className='content-options rows right'>
                                    <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva factura</Button>


                                </td>
                            </tr>
                        </table>

                        <Container className='pt-3'>
                            <Row className='p-2 mb-3' style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                <Col xs={3} className='align-center-vertically'>
                                    <div>
                                        <p className='m-0'>FA-00001-000001</p>
                                    </div>

                                </Col>
                                <Col xs={4} className='align-center-vertically middle'>
                                    <div>
                                        <p className='m-0'>Tostado Cafe Club</p>
                                    </div>
                                </Col>
                                <Col xs={2} className='align-center-vertically middle'>
                                    <div>
                                        <p className='m-0'>10 FEB 2023</p>
                                    </div>
                                </Col>

                                <Col xs={2} className='align-center-vertically middle'>
                                    <div>
                                        <p className='m-0'>$10.000,00</p>
                                    </div>
                                </Col>


                                <Col xs={1} className='align-center-vertically' style={{ textAlign: 'right' }}>
                                    <div>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item ><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item>
                                                <Dropdown.Item ><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                        </Container>



                    </Card>
                </Row>


                <Row>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    Facturas a rendir
                                </td>
                                <td className='content-options rows right'>



                                </td>
                            </tr>
                        </table>

                        <Container className='pt-3'>
                            <Row className='p-2 mb-3' style={{ background: '#F0F0F0', borderRadius: 8 }}>

                                <Col xs={4} className='align-center-vertically middle'>
                                    <div>
                                        <p className='m-0'>YPF</p>
                                    </div>
                                </Col>
                                <Col xs={2} className='align-center-vertically middle'>
                                    <div>
                                        <p className='m-0'>10 FEB 2023</p>
                                    </div>
                                </Col>

                                <Col xs={2} className='align-center-vertically middle'>
                                    <div>
                                        <p className='m-0'>$10.000,00</p>
                                    </div>
                                </Col>

                                <Col xs={3} className='align-center-vertically middle'>
                                    <div>
                                        <p className='m-0'><img className='avatar-40 me-2' src='/avatar_secdevs.png'></img> Lucas Mancuso</p>
                                    </div>
                                </Col>


                                <Col xs={1} className='align-center-vertically' style={{ textAlign: 'right' }}>
                                    <div>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item ><FontAwesomeIcon icon={faCheck} style={{ marginRight: 10, marginLeft: 2 }} />Marcar como rendida</Dropdown.Item>
                                                <Dropdown.Item ><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver archivo</Dropdown.Item>
                                                <Dropdown.Item ><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar archivo</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                        </Container>



                    </Card>
                </Row>
            </Container>

            <Modal show={showFilterModal} onHide={handleCloseFilterModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Filtros</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Orden</Form.Text>
                        <Form.Select className='cover content rows element' id="" value={selectedOrderType} onChange={(event) => handleOrderType(event)}>
                            {orderTypes.map((p, i) => (
                                <option key={i} id={i} value={p}>{p}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-3'>
                        <Form.Text>Mostrar</Form.Text>
                        <Form.Check
                            className='mt-1'
                            type="checkbox"
                            id=""
                            label="Solo mis citas"
                            checked={selectedOnlyMine}
                            onChange={(event) => handleOnlyMine(event)}
                        />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseFilterModal}>
                        Cerrar
                    </Button>
                    <Button variant="primary">
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}