import {params} from '../tools/constants';

export const post = async function (url, token, body){
    try{
        let response = await fetch(params().apiUri + url, {
                method: 'POST',
                mode: "cors",
                headers:{
                    'Accept':'application/x-www-form-urlencoded',
                    'Origin':'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'x-access-token': token},
                body: JSON.stringify(body), 
            });
            
        return response;
    } catch(error){
        console.log("error",error);
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}

export const put = async function (url, token, body){
    try{
        let response = await fetch(params().apiUri + url, {
                method: 'PUT',
                mode: "cors",
                headers:{
                    'Accept':'application/x-www-form-urlencoded',
                    'Origin':'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'x-access-token': token},
                body: JSON.stringify(body), 
            });
            
        return response;
    } catch(error){
        console.log("error",error);
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}

export const get = async function (url, token){
    try{
        let response = await fetch(params().apiUri + url, {
                method: 'GET',
                mode: "cors",
                headers:{
                    'Accept':'application/x-www-form-urlencoded',
                    'Origin':'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'x-access-token': token}
            });
            
        return response;
    } catch(error){
        console.log("error",error);
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}

export const del = async function (url, token, body){
    try{
        let response = await fetch(params().apiUri + url, {
                method: 'DELETE',
                mode: "cors",
                headers:{
                    'Accept':'application/x-www-form-urlencoded',
                    'Origin':'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'x-access-token': token},
                body: JSON.stringify(body), 
            });
            
        return response;
    } catch(error){
        console.log("error",error);
        return ({rdo:1,mensaje:"Ha ocurrido un error"});                
    };
}
